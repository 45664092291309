<template>
  <div class="mb-6">
    <h3 class="text-12 mb-2">Work email address</h3>

    <div class="flex mb-2">
      <div class="w-full">
        <template v-if="emailVerificationForm.sent">
          <form-group
            id="workplace_email"
            name="workplace_email"
            :form="form"
            v-model="form.data.workplace_email.value"
          >
            Enter work email address
          </form-group>
        </template>
        <template v-else-if="!emailVerificationForm.skipped">
          <form-group
            id="workplace_email"
            name="workplace_email"
            :form="emailVerificationForm"
            v-model="emailVerificationForm.data.workplace_email.value"
          >
            Enter work email address
          </form-group>
        </template>
        <div>
          <!-- <button
            type="button"
            class="inline-block bg-blue-200 focus:outline-none rounded-sm text-2xs font-normal px-6 pb-2 py-3 -mt-4 cursor-pointer"
            v-if="!emailVerificationForm.skipped"
            @click="noWorkPlaceEmail"
          >
            Don’t have a work email?
          </button> -->
          <button
            type="button"
            class="inline-block bg-blue-200 focus:outline-none rounded-sm text-2xs font-normal px-6 pb-2 py-3 -mt-4 cursor-pointer"
            v-if="emailVerificationForm.skipped"
            @click="hasWorkPlaceEmail"
          >
            I have a work email!
          </button>
        </div>
      </div>

      <div class="ml-2" v-if="!emailVerificationForm.skipped">
        <template v-if="emailVerificationForm.sent && !emailOtpTimer.done">
          <button
            type="button"
            class="btn btn-blue font-bold text-2xs whitespace-no-wrap h-50px"
            :disabled="true"
          >
            <span>Verify Email</span>
          </button>
        </template>
        <template v-else-if="!emailVerificationForm.skipped">
          <button
            type="button"
            class="btn btn-blue font-bold text-2xs whitespace-no-wrap h-50px"
            @click="verifyEmail"
            :disabled="emailVerificationForm.loading"
          >
            <span v-if="emailVerificationForm.loading">Verifying</span>
            <span v-else>Verify Email</span>
          </button>
        </template>
        <div
          class="text-2xs flex items-center text-blue-500 mt-2"
          :class="{ 'opacity-50': emailVerificationForm.sent }"
        >
          <ion-icon name="alert-circle" class="mr-1 text-xl" />
          <span style="margin-top: 2px" v-if="!emailOtpTimer.done">
            OTP Sent | Resend in {{ emailOtpTimer.time }}.
          </span>
          <span style="margin-top: 2px" v-else>
            Click to continue
          </span>
        </div>
      </div>
    </div>

    <template v-if="getFormError(emailVerificationForm)">
      <div class="alert alert-red-soft mb-10">
        <span class="alert-icon">!</span>
        <span>{{ getFormError(emailVerificationForm) }}</span>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  data() {
    return {
      emailVerificationForm: this.$options.basicForm(
        [
          { name: "workplace_email", rules: "required|email|privateEmail" },
          { name: "channels", value: ["mail"] },
        ],
        { sent: false, skipped: false }
      ),
      emailOtpTimer: {
        time: 40,
        interval: null,
        done: true,
      },
    }
  },
  computed: {},
  methods: {
    startCountDown(timer) {
      this.stopCountDown(timer);

      timer.time = 40;
      timer.done = false;
      timer.interval = setInterval(() => {
        timer.time -= 1;
        if (timer.time <= 0) {
          this.stopCountDown(timer);
          timer.done = true;
        }
      }, 1000);
    },
    stopCountDown(timer) {
      clearInterval(timer.interval);
    },
    noWorkPlaceEmail() {
      //this.googleEvent('Sign Up', 'no_workemail')
      this.emailVerificationForm.skipped = true;
      this.emailVerificationForm.sent = false;
      this.emailVerificationForm.data.workplace_email.value = "";
      this.form.data.workplace_email.rules = "nullable";
      this.form.data.emailOtp.value = "";
      this.form.data.emailOtp.rules = "nullable";
    },
    hasWorkPlaceEmail() {
      //this.googleEvent('Sign Up', 'yes_workemail')
      this.emailVerificationForm.skipped = false;
      this.emailVerificationForm.sent = false;
      this.form.data.workplace_email.rules = "required|email|privateEmail";
      this.form.data.emailOtp.rules = "required|number|length:6";
    },
    async verifyEmail() {
      this.emailVerificationForm.skipped = false;
      this.form.data.emailOtp.rules = "required|number|length:6";
      var valid = true;

      // if (!this.validateForm(this.form)) {
      //   valid = false;
      // }

      if (!this.validateForm(this.emailVerificationForm)) {
        valid = false;
      }

      if (!valid) {
        return false;
      }

      this.emailVerificationForm.loading = true;
      this.emailVerificationForm.error = false;

      await this.sendRequest("otp.request", {
        data: {
          ...this.getFormData(this.emailVerificationForm),
          recipient: this.emailVerificationForm.data.workplace_email.value,
          recipient_type: "email",
        },
        success: () => {
          this.emailVerificationForm.sent = true;
          this.startCountDown(this.emailOtpTimer);
        },
        error: (error) => {
          this.emailVerificationForm.error = error;
        },
      });

      this.emailVerificationForm.loading = false;
    },
  }
}
</script>
